<template>
  <section class="cover" :style="`--parallax: ${parallax}px`">
    <img class="cover__hero" src="@/assets/cover.jpg" alt="" />
  </section>
</template>

<script>
import { computed } from 'vue'
import { useWindowScroll } from '@vueuse/core'

export default {
  props: {
    value: String,
    strength: {
      type: Number,
      default: 100,
    },
  },
  setup() {
    const { y } = useWindowScroll()
    const parallax = computed(() => y.value / 3)

    return {
      parallax,
    }
  },
}
</script>

<style lang="scss">
.cover {
  position: relative;
  z-index: -1;

  &__title {
    max-width: 400px;
    position: absolute;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @include respond-below('tablet') {
      max-width: 250px;
      bottom: unset;
      top: 15%;
    }
  }

  &__hero {
    aspect-ratio: 16/9;
    width: 100%;
    object-position: center var(--parallax);
    object-fit: cover;
    background-color: var(--secondary);

    @include respond-below('phone') {
      aspect-ratio: 1;
    }
  }
}
</style>
