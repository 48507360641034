<template>
  <img class="recipe-image" :src="recipe.image" :alt="recipe.name" :style="`rotate: ${rotation}`" />
</template>

<script>
export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    rotation: String,
  },
}
</script>

<style lang="scss">
.recipe-image {
  background-color: white;
  padding: 0.75rem;
  width: calc(100% - 2rem);
}
</style>
