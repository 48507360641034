import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Campaign from '@/views/Campaign.vue'

Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  return [
    {
      path: '/',
      name: 'Campaign',
      component: Campaign,
      props: true,
    },
    {
      path: '/oppskrift/:id',
      name: 'Oppskrift',
      component: () => import('@/views/Recipe.vue'),
      props: true,
    },
    {
      path: '/personvern',
      name: 'Legal',
      component: () => import('@/views/Legal.vue'),
    },
    {
      path: '*',
      redirect: {
        name: 'Campaign',
      },
    },
  ]
}

const routes = getRoutes()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) return { x: 0, y: 0 }
  },
})

export default router
