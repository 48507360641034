<template>
  <component :is="component" class="recipe-card" :to="to">
    <RecipeImage :recipe="recipe" :rotation="rotation" />
    <h3>{{ recipe.name }}</h3>
  </component>
</template>

<script>
import RecipeImage from './RecipeImage.vue'

export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    rotation: {
      type: String,
      default: '0deg',
    },
    to: {
      type: [String, Object],
    },
  },
  computed: {
    component() {
      if (this.to) return 'router-link'
      return 'article'
    },
  },
  components: {
    RecipeImage,
  },
}
</script>

<style lang="scss">
.recipe-card {
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:is(:hover, :focus-visible) {
    .recipe-image {
      background-color: var(--primary);
    }
  }

  h3 {
    color: var(--primary);
    margin-bottom: 0;
  }
}
</style>
