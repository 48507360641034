<template>
  <ul class="recipe-card-grid">
    <li v-for="(recipe, index) in recipes" :key="recipe.id">
      <RecipeCard :recipe="recipe" :rotation="rotation(index)" :to="`/oppskrift/${recipe.id}`" />
    </li>
  </ul>
</template>

<script>
import RecipeCard from './RecipeCard.vue'

export default {
  props: {
    recipes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    rotation(index) {
      return index % 2 === 0 ? '-3deg' : '5deg'
    },
  },
  components: {
    RecipeCard,
  },
}
</script>

<style lang="scss">
.recipe-card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 4rem;
  justify-content: center;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    width: clamp(300px, 40%, 50%);
    flex-shrink: 0;
  }

  @include respond-below('phone') {
    gap: 1rem;
  }
}
</style>
