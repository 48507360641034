<template>
  <section class="wavy-background">
    <div
      class="wavy-background__top"
      :style="`height: ${height instanceof Array ? height[0] : height}`"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill-opacity="1"
          d="M0,64L80,58.7C160,53,320,43,480,74.7C640,107,800,181,960,202.7C1120,224,1280,192,1360,176L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
    <div class="wavy-background__content">
      <slot />
    </div>
    <div
      v-if="height instanceof Array && height[1]"
      class="wavy-background__bottom"
      :style="`height: ${height instanceof Array ? height[1] : height}`"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill-opacity="1"
          d="M0,64L80,58.7C160,53,320,43,480,74.7C640,107,800,181,960,202.7C1120,224,1280,192,1360,176L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    height: {
      type: [String, Array],
      default: '50%',
    },

    computed: {
      heights() {
        return
      },
    },
  },
}
</script>

<style lang="scss">
.wavy-background {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex-grow: 1;

  &__top {
    background: linear-gradient(0.5turn, var(--primary), var(--secondary));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    fill: white;

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &__bottom {
    background: linear-gradient(0.5turn, var(--primary), var(--secondary));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 0;
    fill: var(--primary);

    svg {
      position: absolute;
      top: 0;
      left: 0;
      translate: 0 calc(-100% + 1px);
      width: 100%;
      z-index: 1;
    }
  }

  &__content {
    z-index: 10;
  }
}
</style>
