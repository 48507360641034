<template>
  <div
    id="app"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav--expanded': isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="{ src: logo, height: 100 }"
          :items="items"
          :title="projectName"
          padding="0.5rem"
          :action="redirect(['Campaign'], { scrollTo: 0 }, this)"
          :isOpen.sync="isExpanded"
        >
          <template #center>
            <img
              src="/assets/logo/philadelphia.svg"
              alt="Philadelphia logo"
              class="philadelphia-logo"
            />
          </template>
        </NavigationComponent>
      </div>
    </div>

    <main>
      <transition mode="out-in" name="route">
        <router-view :key="$route.path" />
      </transition>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import FooterComponent from '@/components/Footer.vue'
import Logo from '@/assets/logo/oreo.png'
import { redirect } from '@/utils'

export default {
  data() {
    return {
      isOnTop: true,
      isExpanded: false,
    }
  },
  computed: {
    view() {
      return this.$route.name || ''
    },
    logo() {
      return Logo
    },
    projectName() {
      return 'Freia'
    },

    navTransition() {
      return this.view === 'Campaign' ? 350 : 5
    },

    items() {
      return [
        {
          label: 'OPPSKRIFTER',
          action: redirect(['Campaign'], { hash: '#oppskrifter' }, this),
        },
        {
          label: 'HJEM',
          action: redirect(['Campaign'], { hash: '' }, this),
        },
      ]
    },
  },
  methods: {
    redirect,
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }
    const storeScroll = () => {
      this.isOnTop = window.scrollY <= this.navTransition
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()
  },
  components: {
    NavigationComponent,
    FooterComponent,
  },
}
</script>

<style lang="scss">
@import 'src/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.legal {
    background: white;
  }
}

.nav {
  position: fixed;
  background: transparent;
  box-shadow: none;

  top: 0;
  width: 100%;
  z-index: 1001;

  transition: color 0.4s ease-in-out, height 0.4s ease-in-out, background 0.4s ease-in-out,
    backdrop-filter 0.4s ease-in-out, -webkit-backdrop-filter 0.4s ease-in-out,
    transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;

  .page--is-scrolled & {
    background-color: var(--primary);
  }

  &--hero {
    .page--is-top & {
      @include respond-below('tablet') {
        position: fixed;
        background: var(--secondary);
      }

      &:not(.nav--expanded) {
        .elder__navigation-component {
          color: var(--secondary) !important;
          @include respond-below('tablet') {
            color: var(--primary) !important;
          }
        }
      }
    }
  }

  .container {
    padding-left: 0;
  }

  .elder__navigation {
    button {
      margin: 0;
    }

    &-logo {
      flex-grow: 0;

      &-image {
        transition: all 400ms ease;
        width: unset;

        @include respond-below('phone') {
          max-height: 72px !important;
        }
      }

      .elder__navigation-component {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-actions {
      flex-grow: 1;
      justify-content: flex-end;

      .elder__navigation-component {
        color: white;

        &:hover {
          cursor: pointer;
          background: none;

          &:after {
            content: '';
            background-color: currentColor;
            width: calc(100% - 2rem);
            height: 2px;
            position: absolute;
            left: 1rem;
            bottom: 0.5rem;
          }
        }
      }
    }

    &-bars {
      flex-grow: 1;
      text-align: right;
      font-size: 1.3rem;
    }
  }

  &--expanded {
    height: 100vh;
    background-color: var(--secondary);

    .elder__navigation-actions {
      padding-left: 1rem;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .elder__navigation-node {
        padding: 0.5rem 2rem;
      }
    }
  }

  .philadelphia-logo {
    background-color: white;
    padding: 0.5rem;
    border-radius: 100%;
    height: 4rem;
    width: 6rem;

    @include respond-below('phone') {
      height: 2rem;
      width: 3rem;
    }
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 300ms ease;
}
</style>
