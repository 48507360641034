<template>
  <div class="campaign page">
    <Cover :strength="150" />

    <WavyBackground :height="breakpoint.smaller('sm').value ? '300px' : undefined">
      <div class="container" id="oppskrifter">
        <div class="intro-text">
          <h1>BAK MED OSS!</h1>
          <p>
            Bli med og lag deilig bakst med OREO og Philadelphia. Under kan du se noen av våre
            favorittoppskrifter!
          </p>
        </div>
        <RecipeCardGrid v-if="recipes?.length" :recipes="recipes" />
      </div>
    </WavyBackground>
  </div>
</template>

<script>
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { computed, defineComponent } from 'vue'

import RecipeCardGrid from '@/components/Recipe/RecipeCardGrid.vue'
import WavyBackground from '@/components/WavyBackground'
import Recipes from '@/assets/recipes.json'
import Cover from '@/components/Cover.vue'

export default defineComponent({
  setup(props, ctx) {
    const recipes = computed(() => Recipes)
    const breakpoint = useBreakpoints(breakpointsTailwind)

    return {
      recipes,
      breakpoint,
    }
  },
  metaInfo() {
    return {
      title: 'Bak med oss! | Oreo',
    }
  },
  components: {
    Cover,
    RecipeCardGrid,
    WavyBackground,
  },
})
</script>

<style lang="scss" scoped>
.campaign {
  text-align: center;

  .intro-text {
    h1 {
      line-height: 1.2;
      margin: 0;
    }

    @include respond-above('phone') {
      margin-block: 4rem;

      p {
        font-size: 1.5rem;
      }
    }

    @include respond-below('phone') {
      margin-block: 1rem 2rem;
    }
  }

  #oppskrifter {
    margin-bottom: 8rem;
  }
}
</style>
